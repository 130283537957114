"use client";

import { createBrowserClient } from "@supabase/ssr";

import { Database } from "./database.types";

export function createClient() {
  return createBrowserClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    {
      auth: {
        // detectSessionInUrl: true,
        // flowType: "pkce",
        // storage: customStorageAdapter,
      },
    },
  );
}

export const supabase = createClient();
