import(/* webpackMode: "eager" */ "/vercel/path0/apps/employee/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/employee/src/app/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/apps/employee/src/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/apps/employee/src/components/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/employee/src/hooks/auth.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.17_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3_5xzfz5brltubi5dm4weiwifwbm/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.17_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3_5xzfz5brltubi5dm4weiwifwbm/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.17_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3_5xzfz5brltubi5dm4weiwifwbm/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.17_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.17_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.4/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.17_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.4/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":\"500\",\"variable\":\"--font-roboto\",\"subsets\":[\"latin\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/packages/design-system/dist/components/ui/tooltip.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/themes.css");
