"use client";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import { createClient } from "@/lib/supabase/client";

/**
 * Used to indetify the users with Sentry
 * @returns
 */
const AuthHooks = () => {
  useEffect(() => {
    const supabase = createClient();

    supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        Sentry.setUser({
          email: session?.user?.email,
          username: session?.user?.email ?? session?.user.phone,
          id: session?.user?.id,
        });
      }
    });
  }, []);

  return null;
};

export default AuthHooks;
